.titleService{
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
    translate: 0;
    margin-top: 5rem;
}

.borderTopText{
    width: 8.4rem;
    height: 1rem;
    background-color: #529512;
}


.nosValuersContainer{
    width: 89%;
}

@media screen and (max-width : 768px) {
    .titleService{
        width: 80%;
    }
    .myServicesSectionStyle{
        width: 100%;
    }

    .ValeurSection{
        padding: 0;
    }

    .nosValuersContainer{
        width: 100%;
    }

    .serviceSectionDivs{
        margin: 0;
        margin-top: 2rem;
    }

    .ServicesDivs{
        padding: 1.3rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    
}

.imgValeurAnimation {
    animation: imgValeurAnimation 8s infinite ease-in-out;
}

@keyframes imgValeurAnimation {
    0% { transform: translate(0px, 0px); }
    20% { transform: translate(5px, -5px); }
    40% { transform: translate(10px, 10px); }
    60% { transform: translate(12px, -12px); }
    80% { transform: translate(10px, 10px); }
    100% { transform: translate(0px, 0px); }
}




@media screen and (min-width : 2005px) {
    .ValeurSection{
        width: 100%;
    }

    .nosValuersContainer{
        width: 100%;
    }

    .titleService{
        translate: -1rem 0px;
    }

    .serviceSectionDivs{
        width: 85.5%;
        margin: auto;
        margin-top: 4rem;
    }
}