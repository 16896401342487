

.loginSection{
    background-color: #111827;
}

@media screen and (max-width : 768px) {
    .loginSection{
        height: 100svh;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .loginSectionForm{
        width: 90%;
    }
}