.loremedia_Ai_chat {
    position: fixed;
    bottom: 0;
    height: 60svh;
    right: 0;
    display: none;
}


.logoIconCLoseCHat {
    position: fixed;
    bottom: 53.5svh;
    width: 2rem;
    right: 1rem;
    display: none;
    cursor: pointer;
    z-index: 1;
    border: 5px solid #1C1A50;
}


.logoIconChat {
    position: fixed;
    bottom: 2rem;
    width: 3.2rem;
    right: 2rem;
    display: flex;
    cursor: pointer;
    animation: chatAiLoremdia 6s infinite;
}

@keyframes chatAiLoremdia {
    0% {
        translate: 0px 0px;
    }

    25% {
        translate: 15px 0px;
    }

    50% {
        translate: 15px 10px;
    }

    75% {
        translate: 0px 10px;
    }

    100% {
        translate: 0px 0px;
    }
}


@media screen and (max-width:768px) {
    .loremedia_Ai_chat {
        height: 70%;
        width: 100%;
    }

    .logoIconCLoseCHat {
        bottom: 64.1%;
        right: 6%;
    }

    .logoIconChat {
        position: fixed;
        bottom: 1.4rem;
        width: 3.2rem;
        right: 1.4rem;
        display: flex;
        cursor: pointer;
    }

}


@media screen and (min-width:2005px) {
    
    .loremedia_Ai_chat {
        height: 45svh;
        width: 15%;
        bottom: 0;
    }

    .logoIconCLoseCHat {
        bottom: 41.5%;
    }

}