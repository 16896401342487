.ProcessContainer {
    background-color: #1c1a50;
    margin-top: 3rem;
}

.ProcessDiv{
    width: 87%;
}

.borderTopTextProcess {
    width: 15rem;
    height: 1rem;
    background-color: #529512;
    translate: 0px -5px;
}

.ProcessTitle {

    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;

}

.MyProcessContent p {
    text-align: justify;
}

@media screen and (max-width:768px) {

    .MyProcessContent h3 {
        text-align: center;
    }

    .lesIconsOfProcess {
        margin: auto;
    }
    
    .ProcessTitle{
        text-align: center;
    }

    .borderTopTextProcess{
        margin: auto;
    }

    .ProcessContainer{
        margin-top: 3rem;
    }

    .ProcessDiv{
        width: 96%;
    }
}



@media screen and (min-width : 2005px) {
    .ProcessDiv{
        width: 84.8%;
    }

}