.HomeDashboard{
    width: calc(100% - 18rem);
    height: calc(100svh - 5rem);
    position: absolute;
    right: 0;
    top: 5rem;
    overflow: auto;
}

.HomeDashboard iframe{
    width: 100%;
    height: calc(100svh - 5rem);
}

.imagePreview:hover{
    opacity: 0.5;
}

@media screen and (max-width:768px) {
    .HomeDashboard {
        width: 100%;
        height: calc(100svh - 5rem);
        z-index: -1;
    }

    .myUpdateForm{
        width: 90%;
        margin-top: 5rem;
    }
}