@media screen and (min-width : 2005px) {

    .FooterSection{
        min-width: 84%;
    }

    #FooterSection{
        margin-top: 3rem;
    }

}