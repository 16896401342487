.myServicesSection {
    background-color: #1c1a50;
    max-width: 100%;
    height: auto;
    padding: 20px;
    margin-top: 10%;
    padding-bottom: 5rem;
}


.myServicesSectionStyle {
    width: 93%;
    margin: auto;
}

.titleService {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
    margin-top: 5rem;
}

.borderTopText {
    width: 8.4rem;
    height: 1rem;
    background-color: #529512;
    transform: translate(0, -5px);
}

.ourServices {
    position: relative;
    width: 93%;
    margin: auto;
    margin-top: 4rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.imageCover {
    background-color: #51951239;
    width: 100%;
    height: 56%;
    position: absolute;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.myImageHover:hover .imageCover {
    opacity: 1;
}

.service_button_more:hover{
    transform: scale(1.03);
    transition: 0.2s;
    color: #1c1a50;
}


@media screen and (max-width: 768px) {
    .myServicesSection {
        transform: translateY(3rem);
        padding: 1px;
    }
    .titleService {
        color: white;
        transform: translate(0);
        text-align: center;
        translate: 0rem 0rem;
        width: 100%; /* Adjusted width for centering */
        margin: auto; /* Added to center horizontally */
        margin-left: auto;
        margin-right: auto;
    }
    .myServicesSectionStyle {
        width: 100%;
    }
    
    .borderTopText {
        margin: auto; /* Center horizontally */
    }

    .ourServices {
        position: relative;
        width: 90%;
        margin: auto;
        margin-top: 4rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}



@media screen and (min-width : 2005px) {

    .myServicesSection{
        width: 100%;
    }

    .titleService{
        margin-left: 12rem;
    }

    .myServicesSectionStyle{
        width: 91%;
    }

    .ourServices {
        position: relative;
        display: flex;
        justify-content: space-between;
        
    }

    .myImageHover{
        min-width: 40rem;
    }

    .imageCover{
        height: 26.5rem;
    }

    .ServiceImageService{
        width: 100%;
        height: 26.5rem;
    }

}