.myListPortfolio{
    font-weight: 500;
    width: 90%;
    font-size: 16px;
    margin: auto;
    margin-top: 1rem;
    color: #1C1A50;
}

.myListPortfolio h2:hover{
    color: #529512;
    transition: 0.3s ;
}



@media screen and (max-width: 768px) {
    .myListPortfolio{
        width: 100%;
        flex-wrap: nowrap;
    }

    .myListPortfolio h2{
        width: max-content;
    }

    .myListPortfolioMobile{
        width: 100%;
        overflow-x: auto;
        height: auto;
        flex-wrap: nowrap;
    }
}
  