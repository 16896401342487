.AboutUsSection {
    width: 99%;
    height: auto;
    margin-top: 5rem;
    translate: 0.5rem 0px;
    background-color: rgba(255, 255, 255, 0.252);
    backdrop-filter: blur(15px);
}


.AboutUsHeader {
    width: 85%;
    height: 9rem;
    margin: auto;
    display: flex;
    position: relative;

}

.LeftDiv {
    width: 50%;
    height: 9rem;
    display: flex;
    align-items: center;
}

#imageLogo {
    width: 53%;
    position: absolute;
    left: -2rem;
    top: 0rem;
}

.RightDiv {
    width: 50%;
    height: 9rem;
    display: flex;
    align-items: center;
    justify-content: right;
}

.RightDiv img {
    width: 65%;
}


.LeftDiv h1 {
    font-family: "Roboto";
    font-weight: 900;
    font-style: bold;
}

.LineOfAbout {
    width: 85%;
    height: 0.05rem;
    background: #1C1A50;
    margin: auto;
}


.AboutUsComponent {
    width: 85%;
    height: auto;
    margin: auto;
    margin-top: 3rem;
    display: flex;
}


.LeftAbout {
    width: 50%;
    height: auto;
}


.RightAbout {
    width: 50%;
    height: auto;
    display: block;
    align-items: center;
    justify-content: right;
}

.imgAbout {
    width: 90%;
    height: auto;
    margin-bottom: 20px;
}

.myTextAbout {
    text-align: justify;
    width: 85%;
}


.buttonReadMoreForMobile {
    display: none;
}

.buttonReadMoreForLaptop {
    translate: 0px 4.5rem;
    display: block;
    color: #76a641;
    background-color: #1C1A50;
}


.hideThisOnLaptop {
    display: none;
}





.animationOfBackground {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -5;
    opacity: 0.03;
    animation: animationOfBackground 10s infinite linear;
}

.animationOfBackground2 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -6;
    opacity: 0.05;
    animation: animationOfBackground2 30s infinite linear;
    width: 20%;
}

.animationOfBackground3 {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -6;
    opacity: 0.03;
    animation: animationOfBackground3 20s infinite linear;
}

.animationOfBackground4 {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -6;
    opacity: 0.05;
    animation: animationOfBackground4 15s infinite linear;
    width: 10%;
}


.imgSectionAnimation img{
    filter: blur(5px);
}

@keyframes animationOfBackground {
    0% { top: 0; left: 0; }
    25% { top: 10%; left: 10%; }
    50% { top: 30%; left: 30%; }
    75% { top: 70%; left: 70%; }
    100% { top: 100%; left: 100%; }
}

@keyframes animationOfBackground2 {
    0% { top: 0; right: 0; }
    25% { top: 10%; right: 10%; }
    50% { top: 30%; right: 30%; }
    75% { top: 70%; right: 70%; }
    100% { top: 100%; right: 100%; }
}

@keyframes animationOfBackground3 {
    0% { bottom: 0; left: 0; }
    25% { bottom: 10%; left: 10%; }
    50% { bottom: 30%; left: 30%; }
    75% { bottom: 70%; left: 70%; }
    100% { bottom: 100%; left: 100%; }
}

@keyframes animationOfBackground4 {
    0% { bottom: 0; right: 0; }
    25% { bottom: 10%; right: 10%; }
    50% { bottom: 30%; right: 30%; }
    75% { bottom: 70%; right: 70%; }
    100% { bottom: 100%; right: 100%; }
}

.serviceTitle {
    font-family: "Roboto";
    font-weight: 900;
    font-style: bold;
}

.backValeurs {
    background-color: #1C1A50;
}



@media screen and (max-width : 768px) {
    .RightDiv {
        display: none;
    }

    .LeftDiv {
        width: 100%;
        height: 4rem;
    }

    .LeftDiv h1 {
        width: 100%;
        text-align: center;
    }

    .buttonReadMoreForLaptop {
        display: none;
    }

    .AboutUsSection {
        margin-top: 2rem;
        width: 98%;
    }

    .AboutUsHeader {
        height: auto;
    }

    .AboutUsComponent {
        display: block;
    }

    .LeftAbout {
        width: 100%;
        margin: auto;
    }

    .myTextAbout {
        width: 100%;
        translate: 0px -1rem;
    }

    .imgAbout {
        display: none;
    }

    #imageLogo {
        width: 55%;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .buttonReadMoreForMobile {
        display: block;
        margin-top: 1rem;
        color: #76a641;
        background-color: #1C1A50;
    }

    .fullTextForMobile {
        display: none;
    }

    .hideThisOnLaptop {
        display: block;
    }

    .animationOfBackground, .animationOfBackground3{
        display: none;
    }

    @keyframes animationOfBackground2 {
        0% { top: 0; left: 0; }
        25% { top: 10%; left: 10%; }
        50% { top: 30%; left: 30%; }
        75% { top: 50%; left: 50%; }
        100% { top: 80%; left: 80%; }
    }


    @keyframes animationOfBackground4 {
        0% { bottom: 0; left: 0; }
        25% { bottom: 10%; left: 10%; }
        50% { bottom: 30%; left: 30%; }
        75% { bottom: 50%; left: 50%; }
        100% { bottom: 80%; left: 80%; }
    }

}


@media screen and (min-width : 2005px) {
    .About_title_NotreAgence{
        margin-top: 4rem;
    }

    #imageLogo{
        width: 35%;
        margin-top: 1.5rem;
    }

    #imageRightTopAbout{
        width: 40%;
    }

    .buttonReadMoreForLaptop{
        display: none;
    }

    .hideThisOnLaptop{
        display: block;
    }

    .myTextAbout{
        margin-top: 4rem;
        font-size: large;
        line-height: 2.3rem;
    }

    .AboutUsSection{
        height: 85svh;
    }
}