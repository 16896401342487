#myHeader{
    background-color: #111827;
    width: 100%;
    height: 5rem;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
    justify-content: space-between;
}


.logoHeaderAdmin{
    width: 100px;
    cursor: pointer;
    translate: 3.5rem 0px;
}

.iconBarsHeader{
    display: none;
}

@media screen and (max-width : 768px) {
    .logoHeaderAdmin{
        width: 100px;
        cursor: pointer;
        translate: 0rem 0px;
    }
}