#LeftSide{
    width: 18rem;
    height: calc(100svh - 5rem);
    background-color: #111827;
    display: block;
    overflow: auto;
}

ul li{
    cursor: pointer;
}

@media screen and (max-width : 768px) {
    #LeftSide{
        width: 100%;
        display: none;
    }
    
    .iconBarsHeader{
        display: block;
    }

    .btnLogout{
        display: none;
    }
}