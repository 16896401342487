.filter-btn {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #4b5563;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .filter-btn:hover {
    background-color: #e2e8f0;
  }
  
  .filter-btn.active {
    background-color: #111827;
    color: #ffffff;
    border-color: #111827;
  }
  
  .btnDelete:hover{
    transform: scale(1.05);
    transition: 0.2s;
  }

  @media screen and (max-width:768px) {
    .ServicesSectionDash{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .MyService{
        width: 95%;
    }
    .myFilterDashService{
        width: 100%;
        overflow-x: auto;
    }
    .myFilterDashService nav{
        width: 100%;
    }
  }