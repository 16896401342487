.imgSlider{
    width: 100%;
    z-index: -1;
    height: auto;
}

.divTextDescPortfolio {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-align: center;
}

.titlePort {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 26px; /* Slightly larger font size */
    margin: 0;
    padding-bottom: 1rem;
}


.borderBottomPort {
    width: 8.5rem;
    height: 3px;
    background-color: white;
    position: absolute;
    left: 50%;
    top: 2.5rem;
    transform: translateX(-50%);
  }

  .checkActive{
    color: #529512;
    border-bottom: 2px solid #529512;
  }


@media only screen and (max-width: 768px) {
    .imgSlider, .divTextDescPortfolio{
      height: 30vh; /* Adjust the height as per your requirement */
      object-fit: cover; /* Ensures the image covers the entire container */
    }
  }
  