.servicesSection {
  width: 75%;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.servicesSection .myservices {
  position: relative;
  cursor: pointer;
}

.servicesSection .hoverCover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.3s ease-in-out;
  z-index: 1;
}

.servicesSection .myservices:hover .hoverCover {
  background-color: #1c1a5074;
}

.servicesSection .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: none;
  border: none;
  font-size: 2rem;
  color: white;
  z-index: 2;
  opacity: 0;
  /* initially hide the button */
}

.servicesSection .myservices:hover .btn {
  transition: 0.3s ease-in-out;
  opacity: 1;
  /* display the button on hover */
}


.previewImage {
  width: 100%;
  height: 100vh; /* Fix typo here */
  margin: auto;
  position: fixed;
  top: 0;
  z-index: 50;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #1c1a5074;
  backdrop-filter: blur(2px);
  padding: 20px;
}

.previewImage .imgprv {
  width: 45%;
}

.iconClose {
  position: fixed;
  width: 5rem;
  color: white;
  display: none;
  z-index: 100;
  top: 1rem; /* Adjusted for better positioning */
  right: 3rem;
  cursor: pointer;
}

.animationClose:hover{
  color: rgba(255, 255, 255, 0.57);
}

@media screen and (max-width:768px) {
  .iconClose {
    position: fixed;
    width: 5rem;
    color: white;
    display: none;
    z-index: 100;
    top: 80%;
    left: 50%; /* Adjusted to center horizontally */
    transform: translate(-50%, -50%); /* Center horizontally and vertically */
  }
  .previewImage .imgprv {
    width: 100%;
  }
}


@media screen and (min-width: 768px) {
  .servicesSection {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .servicesSection {
    width: 95%;
    grid-template-columns: repeat(1, 1fr);
  }
}