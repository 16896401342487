.myVideoPreview {
    width: 100%;
    height: 100vh; /* Corrected typo from 100svh to 100vh */
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, 0.395);
    backdrop-filter: blur(6px);
    z-index: 100;
    display: none;
    justify-content: center;
    align-items: center;
}

.closeIconVideo {
    position: absolute;
    top: 3rem;
    right: 3rem;
    width: 2rem;
    cursor: pointer;
}

.closeIconVideo:hover {
    opacity: 0.5;
    transition: opacity 0.2s;
}


@media screen and (max-width : 768px) {
    
.closeIconVideo {
    position: absolute;
    top: 3rem;
    right: 2rem;
    width: 2rem;
    cursor: pointer;
}

.myVideoPreview {
    padding: 10px;
}



}