.NavHoverText:hover{
    color: #76a641;
    transition: 0.2s ease-out;
}

.NavHoverText{
    font-size: 15px;
}

.DropDownTextHover:hover{
    background-color: #76a641;
    transition: 0.2s ease-out;
}


header{
    z-index: 9999999;
}