.ContactSection{
    width: 85%;
    margin: auto;
}

.ContactTitle{
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
}


.borderTopTextContact{
    width: 15rem;
    height: 1rem;
    background-color: #529512;
    translate: 0px 5px;
}


.myContactsTitles{
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #529512;
}

.myInfoText{
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: light;
}


@media screen and (max-width : 768px) {
    .ContactTitle{
        text-align: center;
    }

    .borderTopTextContact{
        margin: auto;
    }
}


@media screen and (min-width : 2005px) {

    .ContactSection{
        margin-top: 8%;
        width: 100%;
    }
    
    .sectionContactDesktop{
        min-width: 84%;
    }

    .contactIferameMapDesktop{
        height: 35svh;
    }


}