.ClientsContainer {
    background-color: white;
    width: 100%;
    height: 60vh;
}

.titleClients {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
    translate: 6.5rem 0px;
    margin-top: 5rem;
}

.borderTopTextClients {
    width: 28rem;
    height: 1rem;
    background-color: #529512;
    translate: 0px -5px;
}

.carousel-container {
    width: 86%; /* Set the width to 100% */
    height: auto;
    margin: auto;
    overflow: hidden;
    margin-top: 6rem;
}

.ClientsSousContainer {
    width: 97%;
    margin: auto;
}

.carousel {
    display: flex;
    align-items: center;
    animation: slide 60s linear infinite;
}

.imgLogo {
    width: 15%; /* Adjust the width of the images */
    max-width: 200px; /* Limit the maximum width of the images */
    margin-right: 2%; /* Adjust spacing between images */
}

@keyframes slide {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

@media screen and (max-width:768px) {
    .borderTopTextClients{
        width: 9rem;
        margin: auto;
    }

    .titleClients{
        translate: 0px -2rem;
        text-align: center;
    }

    .carousel {
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        animation: slide 10s linear infinite;
        gap: 0rem;
    }

    .carousel-container{
        margin-top: 1rem;
        width: 100%;
    }

    .ClientsContainer{
        height: 35svh;
    }

    .imgLogo {
        width: 14rem;
        margin-right: 0.5rem;
    }
}




@media screen and (min-width : 2005px) {
    .ClientsSousContainer{
        width: 91.8%;
    }

    .ClientsContainer{
        height: 32svh;
    }

    .carousel-container {
        width: 92%;
    }
    

}