.Communication {
    width: 100%;
    height: auto;
    margin-bottom: 5rem;
}

.ComSlider {
    width: 100%;
}

.full_com_text {
    align-items: justify;
    margin-top: -1.9rem;
}


.ComSection {
    width: 82%;
    margin: auto;
    margin-top: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.theGreenLine {
    width: 21.1rem;
    height: 0.6rem;
    background-color: #529512;
    translate: 0px -1.2rem;
}

.comTitle {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: bold;
}


.Comdiv1 {
    width: 50%;
}



.com_paragraph_1 {
    color: #1C1A50;
    font-family: "Roboto";
    font-weight: 400;
    font-style: light;
    align-items: justify;
}

.com_paragraph_2 {
    color: #1C1A50;
    font-family: "Roboto";
    font-weight: 500;
    font-style: light;
    align-items: justify;
}

.com_paragraph_3,
.com_paragraph_4 {
    color: #1C1A50;
    font-family: "Roboto";
    font-weight: 400;
    font-style: light;
    align-items: justify;
}


.com_paragraph_5_green {
    color: #529512;
    font-family: "Roboto";
    font-weight: 400;
    font-style: light;
    align-items: justify;
}

.imgSection {
    width: 49%;
    height: fit-content;
}


.SecondTitle {
    font-family: "Roboto", sans-serif;
    font-weight: 800;
    font-style: bold;
    text-align: center;
    margin-top: 4rem;
}

.comImage{
    width: 60%;
    margin: auto;
    margin-top: 4rem;
}

@media screen and (max-width:768px) {
    .ComSection {
        width: 98%;
        display: block;
    }


    .Comdiv1 {
        width: 91%;
        margin: auto;
    }

    .theGreenLine{
        width: 18.2rem;
        margin: auto;
    }

    .Comdiv1 p{
        text-align: justify;
    }

    .comTitle {
        text-align: center;
    }

    .imgSection{
        margin: auto;
        width: 90%;
        max-height: 50svh;
        margin-top: 2rem;
    }

    .SecondTitle {
        text-align: center;
        margin-top: 2rem;
        font-size: 1.2rem;
    }

    .comImage{
        width: 85%;
    }

}